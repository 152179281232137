@import "../../../node_modules/@qualitas/libs/dist/styles/Variables.scss";

.homepage{
    display: grid;
    grid-template-columns: 380px 1fr;

    .homepage-sidebar{
        min-height: calc(100vh - 80px - 40px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: $qualitas-light-grey;
        padding: 25px 35px;
        .homepage-sidebar-logo{
            width: 100%;
            text-align: center;
            border-bottom: 1px solid black;
            padding-bottom: 20px;
            img{
                margin: auto;
				max-width: 150px;
            }
        }
        .distributor-select{
            width: 100%;
            margin: 15px 0px;
            display: flex;
            align-items: center;
            .ant-select{
                width: 100%;
                border-bottom: 1px solid $qualitas-grey;
                text-align: center;
            }
        }
        .sidebar-pie{
            margin-top: 20px;
        }
        .vehicle-table{
            width: 100%;
            margin: 15px 0px;
            font-size: 13.2px;
            .vehicle-table-header{
                display: grid;
                grid-template-columns: 0.8fr 1.2fr;
                border-bottom: 1px solid $qualitas-grey;
                padding-bottom: 7px;
                span{
                    font-weight: 900;
                    text-align: center;
                    letter-spacing: 1.1px;
                }
            }
            .vehicle-table-body-row{
                width: 100%;
                border: none;
                display: grid;
                grid-template-columns: 0.8fr 1.2fr;
                align-items: center;
                margin: 3px 0px;
                border-left: 4px solid transparent;
                &:hover{
                    cursor: pointer;
                    color: $qualitas-dark-grey;
                }
                span{
                    text-align: center;
                    letter-spacing: 1.1px;
                }
                transition: 0.3s;
            }
            .selectedInstitution-pin{
                font-weight: 900;
                border-left: 4px solid $qualitas-green-dark;
                transition: 0.3s;
            }
        }
        .percent-list{
            margin-top: 20px;
            p{
                margin: 0;
            }
            width: 100%;
            .percent-item{
                margin-top: 10px;
                gap: 20px;
                display: grid;
                grid-template-columns: 0.9fr 1.1fr;
                .percent-value{
                    margin-top: 5px;
                    text-align: end;
                    font-size: 22px;
                    font-weight: 600;
                    font-family: $qualitas-font-title;
                    span{
                        font-size: 12px;
                    }
                }
                .percent-description{
                    padding-left: 20px;
                    font-weight: 600;
                    p{
                    font-weight: normal;
                    }
                }
                .green-description{
                    border-left: 4px solid $qualitas-green-dark;
                }                
                .dark-grey-description{
                    border-left: 4px solid $qualitas-dark-grey;
                }
                .grey-description{
                    border-left: 4px solid $qualitas-grey;
                }
            }
        }
    }

    .homepage-tables{
        overflow: hidden;
        p, h2{
            margin: 0;
        }
        padding: 20px;
        .homepage-header{
            display: grid;
            grid-template-columns: 300px 1fr;

            width: 100%;
            h2{
                font-size: 18px;
                font-weight: 600;
                font-family: $qualitas-font-title;
            }
            span{
                color: $qualitas-green;
            }
        }
        .homepage-table-selector{
            display: flex;
            justify-content: center;
            margin-right: 300px;
            align-items: center;

            button{
                padding: 0px 20px;
                border: none;
                background-color: transparent;
                color: $qualitas-grey;
                font-weight: bold;
                letter-spacing: 0.6px;
                &:hover{
                    cursor: pointer;
                }
            }
            .inrevision-btn{
                border-left: 2px solid $qualitas-black;
            }
            .selected-btn{
                color: $qualitas-black;
            }
        }
    }
}

.table-all-contracts, .table-in-revision {
    .btnDelete, .send-btn{
        border: none;
        background-color: transparent;
    
        :disabled{
            border: none;
            background-color: transparent;
            color: rgb(173, 173, 173);	
        }
        
        &:hover{
            cursor: pointer;
        }
    }
}
