@import "~/node_modules/@qualitas/libs/dist/styles/Variables.scss";

.distributor-data {
	.info-disclaimer {
		padding: 16px 0;
		display: block;
		font-style: oblique;
		font-weight: 600;
	}
}
.semi-colon-msg{
	font-size: 12px;
	font-style: oblique;
}

.notificacions-msg{
	width: 100%;
	padding-top: 20px;
	border-top: 1px solid $qualitas-grey;
}