@import "~/node_modules/@qualitas/libs/dist/styles/Variables.scss";

.crs-text-areas{
	span{
		font-size: 13px;
	}
}

.crs {

	.crs-radio-group {
		padding-top: 16px;
	}
	.crs-radio {
		white-space: normal;
		margin-bottom: 15px;
		padding-bottom: 8px;
	}
	.tooltip {
		background: white;
		color: $qualitas-green;
		border-radius: 60px;
		font-size: 12px;
		padding-left: 3px;
		display: inline-block;
		margin-left: 2px;
		font-weight: bold;
		position: relative;
		bottom: 2px;

		&:hover {
			color: $qualitas-green-dark;
			cursor: pointer
		}
	}

	&-radio.ant-radio-wrapper {
		white-space: normal;
		padding-bottom: 10px;
		width: 100%;
		padding-bottom: 16px;
	}
}



