@import "~/node_modules/@qualitas/libs/dist/styles/Variables.scss";

.legalInvestorStatus {
	.radio-group {
		padding-left: 16px;

		.radio-option {
			white-space: normal;
			margin-bottom: 15px;
			width: 100%;
		}
	}

	.question-two {
		border: 1px solid lightgray;
		margin-top: 10px;

		&-checkbox.ant-checkbox-wrapper {
			margin: 0px;
			width: 100%;
			padding-left: 10px;
	
			&:nth-child(odd) {
				border-bottom: 1px solid lightgray;
				background: #F2F2F2;
			}
	
			&:nth-child(even) {
				border-bottom: 1px solid lightgray;
			}
		}
	
		&-input {
			margin: 8px 8px 0 8px;
		}
	}
	  
	.options{
		margin: 5px;
		text-align: left;
		font-size: medium;
	}

	.ant-alert-banner {
		width: 100%;
		font-weight: bold;
	}
}