@import "~/node_modules/@qualitas/libs/dist/styles/Variables.scss";

.investor-list{
	border-top: 1px solid $qualitas-grey;
	padding-top: 20px;
	margin-top: 10px;
}

.subitem-line{
	padding-bottom: 20px;
}

.numSignaturies{
	display: flex;
	justify-content: center;
	align-items: center;
	span{
		padding-top: 5px;

	}
}
.row-wtdh{
	width: 100%;
}

.ant-col.delete-investor {
	display: flex;

	button {
		align-self: end;
	}
}