@import "~/node_modules/@qualitas/libs/dist/styles/Variables.scss";

.stepper {
    display: block;
    min-height: 80vh;

	.ant-steps-item-finish .ant-steps-item-container{
		.ant-steps-item-icon{
			background-color: $qualitas-green;
			border: none;
			svg{
				fill: black;
			}
		}
		.ant-steps-item-content .ant-steps-item-title{
			color: $qualitas-grey;
		
		}
	}
	.ant-steps-item-active .ant-steps-item-container{
		.ant-steps-item-icon {
			background-color: $qualitas-white;
			border: none;
			.ant-steps-icon{
				color: $qualitas-black
			}
				
		}
		.ant-steps-item-content .ant-steps-item-title{
			font-weight: bold;
		
		}
	}
	.ant-steps-item-wait .ant-steps-item-container{
		.ant-steps-item-icon {
			background-color: $qualitas-grey;
			border: none;
			.ant-steps-icon{
				color: $qualitas-black
			}
				
		}
	}

	.main-content{
		display: grid;
		grid-template-columns: 380px 1fr;	
	}

	.stepper-wrapper{
		min-height: calc(100vh - 80px - 40px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: $qualitas-light-grey;
        padding: 25px 35px;
	}

	.stepper-sidebar-logo{
		width: 100%;
		text-align: center;
		margin-bottom: 20px;
		img{
			margin: auto;
			max-width: 150px;
		}
	}

    .required::before {
		content: "*";
		color: red;
	}

	.ant-result-info .ant-result-icon > .anticon {
		color: $qualitas-dark-grey;
	}
}
