@import "~/node_modules/@qualitas/libs/dist/styles/Variables.scss";

.legalInvestorForm {
	.nif-radio-group {
		background: #F2F2F2;
		padding: 10px;

		&.block-error {
			border: 1px solid red;
		}
	}
}



