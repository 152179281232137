@import "~/node_modules/@qualitas/libs/dist/styles/Variables.scss";

.physicalInvestorStatus {
	.options{
		margin: 5px;
		text-align: left;
		font-size: medium;
	}
	.ant-alert-banner {
		width: 100%;
		font-weight: bold;
	}
}