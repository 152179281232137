@import "~/node_modules/@qualitas/libs/dist/styles/Variables.scss";

.RepresentativesForm {
	.signatorieRow{
		border-bottom: 2px solid rgb(223, 223, 223);
		padding: 25px 0 35px 0;
	}
	.signatorieRow:last-of-type{
		border-bottom: none;
	}
	.signatorieRow:first-of-type{
		padding: 25px 0 35px 0;
	}

	.prp5Q{
		margin: 25px 0 25px 0
	}
}

.dlt-btn{
	margin-top: 24px;
}

.isSignatory{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	label{
		margin-bottom: 5px;
	}
}