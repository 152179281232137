@import "~/node_modules/@qualitas/libs/dist/styles/Variables.scss";

.data-wrapper{
	width: 760px;
	margin: 70px auto 30px auto;
	font-family: $qualitas-font-text;

	p{
		margin: 0;
	}

	h2, h3{
		font-family: $qualitas-font-title;
		font-size: 22px;
		font-weight: bold;
		margin: 0;
	}

	h3{
		font-size: 16px;
	}
	.arrow-btn{
		height: 45px;
		font-size: 22px;
	}
	.caret-green{
		color: $qualitas-green;
		font-size: 18px;
		margin-right: 5px;
	}
	.data-form-wrapper{
		padding-bottom: 24px;
		margin: 20px 0 0 0;
	}

	label{
		font-size: 13px;
		letter-spacing: '10%';
	}

	.form-input{
		display: flex;
	}
	.error, .error-big {
		display: none;
		font-size: 12px;
		color: red;
		font-style: italic;

		&.show {
			display: block;
			position: relative;
		}
	}
	.error-big{
		font-size: 14px;
	}
	.input-field {
		width: 100%;
	}
	.data-protection {
		color: $qualitas-dark-grey;
		font-size: 13px;
		line-height: 14px;
		strong{
			font-size: 14px;
			padding-bottom: 5px;
			color: $qualitas-black;
		}
	}

}
.ant-spin-text {
	color: $qualitas-black;
	margin-top: 20px;
}