@import "~/node_modules/@qualitas/libs/dist/styles/Variables.scss";

.tooltip-benef{
	color: $qualitas-green-dark;
	font-weight: bold;
	margin-left: 5px;
	&:hover{
		color: $qualitas-green-dark;
		cursor: pointer;
	}
}

.dlt-btn-benef{
	margin-top: 22px;
	margin-left: 30px;
}