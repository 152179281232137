@import "../node_modules/@qualitas/auth/dist/Auth.scss";
@import "../node_modules/@qualitas/navbar/dist/Navbar.scss";
@import "../node_modules/@qualitas/libs/dist/styles/Variables.scss";
@import "../node_modules/@qualitas/libs/dist/styles/Fonts.scss";
@import "../node_modules/@qualitas/libs/dist/styles/Overwriting.scss";
@import '~antd/dist/antd.css';
@import '~devextreme/dist/css/dx.light.css';

.navbar-qf {
    min-height: 80px;
}

.footer-qf {
    min-height: 40px;
}

.ant-layout {
    background-color: #ffffff;
}

.anticon-check-circle{
	svg{
		fill: $qualitas-green-dark
	}
}

.anticon-info-circle{
	svg{
		fill: $qualitas-green-dark
	}
}

.anticon-warning{
	svg{
		fill: red;
	}
}

@include antd-overwrite;

@include navbar;

