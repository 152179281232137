@import "~/node_modules/@qualitas/libs/dist/styles/Variables.scss";

.physicalInvestorForm {
	.options{
		margin:5px;
		text-align: left;
		font-size: medium;
	}
	  
	.nif-radio {
		&-group {
			background: #F2F2F2;
			padding: 10px;

			&.block-error {
				border: 1px solid red;
			}
		}

		&-reason {
			margin-bottom: 15px;
			height: 30px;
			line-height: 30px;
		}

		width: 100%;
		white-space: normal;
		margin-bottom: 15px;
	}
}