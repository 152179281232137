@import "~/node_modules/@qualitas/libs/dist/styles/Variables.scss";

.suitability-test {

	.info, .test {
		padding: 8px;
	}

	.ant-checkbox-wrapper + .ant-checkbox-wrapper{
		margin: 0px;
	}
	
	.test {
		.ant-checkbox-group, .ant-radio-group {
			padding-top: 8px;
			display: flex;
			flex-direction: column;
		}

		.table {
			padding-top: 8px;

			.ant-table-thead > tr > th {
				border: 1px solid #cfcdcd;
			}

			.checkbox {
				text-align: center;
			}
		}
	}

	.option-disclaimer {
		display: none;

		&.show {
			display: block;
			padding-top: 8px;
			font-style: italic;
			font-weight: 600;

			.anticon.anticon-warning {
				color: #ffcc00;
			}
		}
	}
}

.not-suitable-confirmation-modal {

	.anticon-warning {
		color: #ffcc00;
   		margin-right: 8px;
	}

	.ant-modal-close {
		display: none;
	}

	.not-suitable-text {
		text-align: center;
	}
}